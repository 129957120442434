import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import cls from './spoiler.module.scss'
import Text from '~src/new/shared/ui/Text/Text'
import { Icon } from '@material-ui/core'
import { Button } from '~src/new/shared/ui/Button/Button'

/**
 * A component that displays a collapsible spoiler with a title and content.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {any} props.children - The content of the spoiler.
 * @param {string | JSX.Element} [props.title] - The title of the spoiler.
 * @param {JSX.Element} [props.icon] - The icon of the spoiler.
 * @param {string} [props.className] - Additional CSS class names to be applied to the spoiler.
 * @param {string} [props.classNameHeader] - Additional CSS class names to be applied to the header.
 * @param {string} [props.classNameContent] - Additional CSS class names to be applied to the content.
 * @param {Function} [props.onChange] - The function to call when the spoiler is expanded or collapsed.
 * @param {boolean} [props.isClickableContainerToOpen] - Determines if the spoiler is clickable to open.
 * @param {boolean} [props.isContentGrayBlock] - Determines if the content block is gray.
 * @param {boolean} [props.isExpandedExtend] - Determines if the spoiler is expanded.
 * @param {boolean} [props.isDefaultExpanded] - Determines if the spoiler is expanded by default.
 * @param {JSX.Element} [props.additionalIcons] - The icon of the spoiler.
 */
const Spoiler = ({
  title,
  children,
  icon,
  className,
  classNameHeader,
  classNameContent,
  onChange,
  isContentGrayBlock,
  isClickableContainerToOpen,
  isExpandedExtend,
  isDefaultExpanded,
  additionalIcons,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedExtend ?? isDefaultExpanded ?? false)
  const [contentHeight, setContentHeight] = useState(0)
  const [isExpandingEnded, setIsExpandingEnded] = useState(false)
  const scrollHeight = useRef(0)
  /** @type {React.MutableRefObject<any>} */
  const timer = useRef(null)

  /**
   * Toggles the expanded state of the spoiler.
   * @param {boolean} [value] - The value to set the expanded state to.
   */
  const toggleSpoiler = value => {
    setIsExpandingEnded(false)
    setTimeout(() => {
      onChange && onChange(value ?? !isExpanded)
      setIsExpanded(value ?? !isExpanded)
    }, 10)
  }

  /**
   * Sets the height of the content element when it is rendered.
   * @param {HTMLElement} node - The content element.
   */
  const handleContentRef = node => {
    if (node !== null) {
      scrollHeight.current = node.scrollHeight
      setContentHeight(node.scrollHeight)
    }
  }

  const handelSpoilerClick = useCallback(() => {
    if ((isClickableContainerToOpen || isContentGrayBlock) && !isExpanded) toggleSpoiler()
  }, [isExpanded])

  useEffect(() => {
    if (isExpanded) {
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        if (setIsExpandingEnded) setIsExpandingEnded(true)
      }, 350)
    }
  }, [isExpanded])

  useEffect(() => {
    if (isExpandedExtend !== undefined) toggleSpoiler(isExpandedExtend)
  }, [isExpandedExtend])

  const iconElement = isContentGrayBlock ? (
    <Button isArrow className={cls.arrow} />
  ) : (
    icon || <Icon>keyboard_arrow_right</Icon>
  )

  const titleElement =
    typeof title === 'string' ? (
      <>
        <Text variant="SUBHEADER" className={cls.title}>
          {title}
        </Text>
      </>
    ) : (
      title
    )

  /** @type {number|string} */
  let maxHeight = 0
  let overflow = 'hidden'

  if (isExpanded)
    if (isExpandingEnded) {
      maxHeight = 'none'
      overflow = 'visible'
    } else maxHeight = contentHeight

  return (
    <div
      className={classNames(cls.spoiler, className, {
        [cls.grayBlock]: isContentGrayBlock,
      })}
      onClick={handelSpoilerClick}
      style={{
        cursor:
          (isClickableContainerToOpen || isContentGrayBlock) && !isExpanded ? 'pointer' : 'auto',
      }}
    >
      {titleElement ? (
        <div
          className={classNames(cls['spoiler-header'], classNameHeader, {
            [cls.grayBlockHeader]: isContentGrayBlock,
          })}
          onClick={() => toggleSpoiler()}
        >
          {titleElement}
          <div className={cls.icons}>
            {isExpanded && additionalIcons ? (
              <div className={cls.additionalIcons}>{additionalIcons}</div>
            ) : null}
            <div
              className={classNames(cls['spoiler-icon'], !icon && !isContentGrayBlock && cls.icon)}
              style={{
                transform: isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)',
              }}
            >
              {iconElement}
            </div>
          </div>
        </div>
      ) : null}
      <div
        className={classNames(cls['spoiler-content'])}
        style={{ maxHeight, overflow }}
        ref={handleContentRef}
      >
        <div
          className={classNames(classNameContent, {
            [cls.grayBlockContent]: isContentGrayBlock,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default Spoiler
